/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { debounce } from "lodash";
import { Plugin, EditorState, Transaction } from "prosemirror-state";
import { toggleMark, setBlockType, chainCommands } from "prosemirror-commands";
import classnames from "classnames/bind";
import ReactPluginView from "pages/story-editor/plugins/react-plugin-view";
import { textAreaSchema } from "pages/story-editor/prosemirror/schema";
import { removeFormatting, toggleList } from "pages/story-editor/operations/commands";
import Link from "pages/story-editor/plugins/toolbar/link/link";
import { Schema, MarkType, NodeType, Node } from "prosemirror-model";
import { EditorView } from "prosemirror-view";
import { compose } from "redux";
import { connect } from "react-redux";
import { PartialAppState } from "./state";
import { selectIsDesktopSizeViewport } from "store/viewport";
import styles from "./toolbar.module.css";

import {
  Bold,
  Italic,
  SuperScript,
  BulletList,
  RemoveFormatting,
  Underline,
  Link as LinkIcon,
  StrikeThrough,
  NumberedList,
  H6,
  H2,
  H3,
  H4,
  H5,
  SubScript
} from "components/icons/formatting-toolbar";
import Caret from "components/icons/caret";
import Headings from "pages/story-editor/plugins/toolbar/headings/headings";
import { getMarkAttrs, addMarkToSelection, removeMarkFromSelection } from "pages/story-editor/prosemirror/utils";
import { setTextSelection } from "pages/story-editor/operations/selection";

const cx = classnames.bind(styles);

const schema = textAreaSchema;

function isMarkActive(type: MarkType<Schema>) {
  return function(editorState: EditorState) {
    let { from, $from, to, empty } = editorState.selection;
    if (empty) {
      return type.isInSet(editorState.storedMarks || $from.marks());
    } else {
      return editorState.doc.rangeHasMark(from, to, type);
    }
  };
}

function hasParentBlockNodeType(nodeType: NodeType, attrs = {}) {
  return function(editorState: EditorState) {
    const { $from } = editorState.selection;

    let found = false;

    for (let depth = $from.depth; depth > 0; depth--) {
      const node = $from.node(depth);

      if (node.sameMarkup(nodeType.create(attrs))) {
        found = true;
      }
    }

    return found;
  };
}

function isSelectedInlineNodeType(nodeType: NodeType, attrs = {}) {
  return function(editorState: EditorState) {
    const fragment = editorState.selection.content().content;

    if (editorState.selection.empty) {
      return false;
    }

    let foundNodes: Array<Node> = [];

    fragment.descendants((node) => {
      node.descendants((node) => {
        if (node.type.inlineContent) {
          foundNodes.push(node);
          return false;
        }
        return false;
      });
    });

    if (foundNodes.length === 0) {
      return false;
    } else {
      return foundNodes.every((foundNode) => foundNode.hasMarkup(nodeType, attrs));
    }
  };
}

const selectIconSize = (isDesktopSizeViewport: boolean) => {
  return {
    width: isDesktopSizeViewport ? "24" : "20",
    height: isDesktopSizeViewport ? "24" : "20"
  };
};

const renderSelectedHeading = (level: number) => {
  switch (level) {
    case 2:
      return <H2 />;
    case 3:
      return <H3 />;
    case 4:
      return <H4 />;
    case 5:
      return <H5 />;
    case 6:
      return <H6 />;
    default:
      return <H2 />;
  }
};

const getActiveHeading = () => (editorState: EditorState) => {
  for (let i = 2; i <= 6; i++) {
    if (isSelectedInlineNodeType(schema.nodes.heading, { level: i })(editorState)) {
      return renderSelectedHeading(i);
    }
  }
  return null;
};

interface Props {
  state: EditorState;
  view: EditorView;
  dispatch: (tr: Transaction<Schema>) => void;
}

interface State {
  showLinkInput: boolean;
  showHeadingsDropDown: boolean;
  showToolbar: boolean;
  currentlyActiveSubMenu: string | null;
}

class Toolbar extends React.Component<Props & StateProps, State> {
  private formattingToolbarRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props & StateProps) {
    super(props);
    this.formattingToolbarRef = React.createRef();
    this.delayedRender = this.delayedRender.bind(this);
    this.delayedRender = debounce(this.delayedRender, 500);
    this.state = {
      showToolbar: false,
      showLinkInput: false,
      showHeadingsDropDown: false,
      currentlyActiveSubMenu: null
    };
  }

  delayedRender = () => {
    if (!this.state.showToolbar && this.isTextSelected()) {
      setTimeout(() => this.setState({ showToolbar: true }), 250);
    }

    if (this.state.showToolbar && !this.isTextSelected()) {
      this.setState({ showToolbar: false });
    }
  };

  closeLinkInput = () => this.setState({ currentlyActiveSubMenu: null });

  setLink = (href?: String, isNoFollow?: boolean) => {
    let attrs: any = null;

    attrs = { href };
    if (isNoFollow) {
      attrs["rel"] = "nofollow";
    }

    this.closeLinkInput();
    const tr =
      attrs.href && attrs.href.length > 1
        ? addMarkToSelection(this.props.state, schema.marks.link, attrs)
        : removeMarkFromSelection(this.props.state, schema.marks.link);

    this.props.dispatch(setTextSelection(tr, this.props.state, tr.selection.to));
    return;
  };

  setHeading = (e: React.MouseEvent, level: number) => {
    e.preventDefault();
    e.stopPropagation();
    this.setActiveSubMenu(null);
    chainCommands(setBlockType(schema.nodes.heading, { level: level }), setBlockType(schema.nodes.paragraph))(
      this.props.state,
      this.props.dispatch
    );
  };

  setActiveSubMenu = (menuType: string | null) =>
    this.setState((prevState) =>
      prevState.currentlyActiveSubMenu ? { currentlyActiveSubMenu: null } : { currentlyActiveSubMenu: menuType }
    );

  toggleMarkNode(e: React.MouseEvent<HTMLElement>, item: MarkType) {
    e.preventDefault();
    e.stopPropagation();
    toggleMark(item)(this.props.state, this.props.dispatch);
    this.setActiveSubMenu(null);
  }

  toggleListNode(e: React.MouseEvent<HTMLElement>, item: NodeType) {
    e.preventDefault();
    e.stopPropagation();
    toggleList(schema, item)(this.props.state, this.props.dispatch);
    this.setActiveSubMenu(null);
  }

  resetToolbar = (e: React.MouseEvent<HTMLElement> | Event) => {
    if (
      this.formattingToolbarRef &&
      this.formattingToolbarRef.current &&
      e &&
      e.target &&
      this.formattingToolbarRef.current.contains(e.target as HTMLElement)
    ) {
      return;
    }

    this.setState({ currentlyActiveSubMenu: null });
  };

  isHeadingActive = (level: number) => isSelectedInlineNodeType(schema.nodes.heading, { level })(this.props.state);

  handleKeyPress = (event: KeyboardEvent) => {
    const { keyCode, metaKey, ctrlKey } = event;
    //Trigger link popup on Meta+K or Ctrl+k key combination
    if (!this.props.state.selection.empty && keyCode === 75 && (metaKey || ctrlKey)) {
      // Check if event originated in a prose mirror editor near the toolbar
      if (this.formattingToolbarRef.current) {
        const eventElement = event.target as HTMLElement;
        const eventParent = eventElement.closest("#prosemirror-text-area");
        const formattingToolbarParent = this.formattingToolbarRef.current.closest("#prosemirror-text-area");
        if (eventParent !== formattingToolbarParent) return;
      }
      this.setActiveSubMenu("link");
      this.preventEventFlowToProseMirror(event);
    }
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.resetToolbar);
    document.addEventListener("keydown", this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.resetToolbar);
    document.removeEventListener("keydown", this.handleKeyPress);
  }

  componentDidUpdate() {
    this.delayedRender();
  }

  isTextSelected() {
    const selection = this.props.state.selection;
    // Had to checked anchor is zero because initial render selection is not empty
    return !(selection.empty || selection.anchor === 0);
  }

  isAnyListActive() {
    if (
      hasParentBlockNodeType(schema.nodes.bullet_list)(this.props.state) &&
      hasParentBlockNodeType(schema.nodes.ordered_list)(this.props.state)
    ) {
      return false;
    } else if (
      hasParentBlockNodeType(schema.nodes.bullet_list)(this.props.state) ||
      hasParentBlockNodeType(schema.nodes.ordered_list)(this.props.state)
    ) {
      return true;
    } else {
      return false;
    }
  }

  renderListIcon() {
    if (hasParentBlockNodeType(schema.nodes.bullet_list)(this.props.state)) {
      return <BulletList />;
    } else if (hasParentBlockNodeType(schema.nodes.ordered_list)(this.props.state)) {
      return <NumberedList />;
    } else {
      return <BulletList {...selectIconSize(this.props.isDesktopSizeViewport)} />;
    }
  }

  isSuperOrSubScriptActive() {
    if (
      isMarkActive(schema.marks.superscript)(this.props.state) &&
      isMarkActive(schema.marks.subscript)(this.props.state)
    ) {
      return false;
    } else if (
      isMarkActive(schema.marks.superscript)(this.props.state) ||
      isMarkActive(schema.marks.subscript)(this.props.state)
    ) {
      return true;
    } else {
      return false;
    }
  }

  renderSubScriptOrSuperScriptIcon() {
    if (
      isMarkActive(schema.marks.superscript)(this.props.state) &&
      !isMarkActive(schema.marks.subscript)(this.props.state)
    ) {
      return <SuperScript />;
    } else if (
      isMarkActive(schema.marks.subscript)(this.props.state) &&
      !isMarkActive(schema.marks.superscript)(this.props.state)
    ) {
      return <SubScript {...selectIconSize(this.props.isDesktopSizeViewport)} />;
    } else {
      return <SubScript {...selectIconSize(this.props.isDesktopSizeViewport)} />;
    }
  }

  preventEventFlowToProseMirror(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  render() {
    const selection = this.props.state.selection,
      { from, to } = selection;

    let style = {},
      position = { left: 0, top: 0 };

    interface ScreenCoordinates {
      top: number;
      bottom: number;
      left: number;
      right: number;
    }
    //Only compute the position when there is a selection
    if (!selection.empty) {
      let start: ScreenCoordinates = {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0
        },
        end: ScreenCoordinates = {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0
        };

      // These are in screen coordinates
      try {
        start = this.props.view.coordsAtPos(from);
        end = this.props.view.coordsAtPos(to);
      } catch (e) {
        return null;
      }
      // The box in which the tooltip is positioned, to use as base
      let box = this.props.view.dom.parentNode && (this.props.view.dom.parentNode as Element).getBoundingClientRect();
      // Find a center-ish x position from the selection endpoints (when
      // crossing lines, end may be more to the left)
      let left = (end.left - start.left) / 2;
      const rootStyles = getComputedStyle(document.body);
      const navBarWidth = parseFloat(rootStyles.getPropertyValue("--navbar-width")) * 10;
      const formatToolbarWidth = parseFloat(rootStyles.getPropertyValue("--format-toolbar-width")) * 10;
      let leftPosition = start.left - navBarWidth + left - formatToolbarWidth / 2;

      if (box) {
        position = {
          left: leftPosition < 0 ? 22 : leftPosition, // 22 is just random number to make it visible on smaller screen
          top: box.bottom - (start.top + 20) // 20 is approx height of the text
        };

        style = {
          transform: `translate3d(0, -${position.top}px, 0)`,
          position: "relative"
        };
      }
    }

    return (
      <div className={styles["formatting-toolbar-wrapper"]} ref={this.formattingToolbarRef} style={style}>
        <ul
          className={cx(
            "format-toolbar",
            { "format-toolbar--active": this.state.currentlyActiveSubMenu },
            { "format-toolbar--focused": this.isTextSelected() && this.state.showToolbar }
          )}>
          <li
            key="format-toolbar-bold"
            onMouseDown={(e) => this.toggleMarkNode(e, schema.marks.strong)}
            className={cx("format-toolbar-item", { "is-active": isMarkActive(schema.marks.strong)(this.props.state) })}>
            <Bold {...selectIconSize(this.props.isDesktopSizeViewport)} />
          </li>
          <li
            key="format-toolbar-italic"
            onMouseDown={(e) => this.toggleMarkNode(e, schema.marks.em)}
            className={cx("format-toolbar-item", { "is-active": isMarkActive(schema.marks.em)(this.props.state) })}>
            <Italic {...selectIconSize(this.props.isDesktopSizeViewport)} />
          </li>

          <li
            key="format-toolbar-underline"
            onMouseDown={(e) => this.toggleMarkNode(e, schema.marks.underline)}
            className={cx("format-toolbar-item", {
              "is-active": isMarkActive(schema.marks.underline)(this.props.state)
            })}>
            <Underline {...selectIconSize(this.props.isDesktopSizeViewport)} />
          </li>

          <li
            key="format-toolbar-strikethrough"
            onMouseDown={(e) => this.toggleMarkNode(e, schema.marks.strikethrough)}
            className={cx("format-toolbar-item", {
              "is-active": isMarkActive(schema.marks.strikethrough)(this.props.state)
            })}>
            <StrikeThrough {...selectIconSize(this.props.isDesktopSizeViewport)} />
          </li>

          <li
            key="format-toolbar-superscript-and-subscript"
            onMouseDown={(e) => {
              this.resetToolbar(e);
              this.setActiveSubMenu("superAndSubScript");
            }}
            className={cx("format-toolbar-item", {
              "is-active": this.isSuperOrSubScriptActive()
            })}>
            {this.renderSubScriptOrSuperScriptIcon()}
            <Caret variant="down" width={16} height={16} />
            {this.state.currentlyActiveSubMenu === "superAndSubScript" && (
              <ul className={cx("format-toolbar-submenu-wrapper", "format-toolbar-super-and-sub-script-submenu")}>
                <li
                  className={styles["format-toolbar-submenu-item"]}
                  onMouseDown={(e) => this.toggleMarkNode(e, schema.marks.superscript)}>
                  <SuperScript {...selectIconSize(this.props.isDesktopSizeViewport)} />
                </li>
                <li
                  className={styles["format-toolbar-submenu-item"]}
                  onMouseDown={(e) => this.toggleMarkNode(e, schema.marks.subscript)}>
                  <SubScript {...selectIconSize(this.props.isDesktopSizeViewport)} />
                </li>
              </ul>
            )}
          </li>
          <li
            key="format-toolbar-list"
            onMouseDown={(e) => {
              this.resetToolbar(e);
              this.setActiveSubMenu("lists");
            }}
            className={cx("format-toolbar-item", {
              "is-active": this.isAnyListActive()
            })}>
            {this.renderListIcon()}
            <Caret variant="down" width={16} height={16} />
            {this.state.currentlyActiveSubMenu === "lists" && (
              <ul className={cx("format-toolbar-submenu-wrapper", "format-toolbar-lists-submenu")}>
                <li
                  className={styles["format-toolbar-submenu-item"]}
                  onMouseDown={(e) => this.toggleListNode(e, schema.nodes.bullet_list)}>
                  <BulletList {...selectIconSize(this.props.isDesktopSizeViewport)} />
                </li>
                <li
                  className={styles["format-toolbar-submenu-item"]}
                  onMouseDown={(e) => this.toggleListNode(e, schema.nodes.ordered_list)}>
                  <NumberedList {...selectIconSize(this.props.isDesktopSizeViewport)} />
                </li>
              </ul>
            )}
          </li>
          <li
            key="format-toolbar-link"
            onMouseDown={(e) => {
              this.preventEventFlowToProseMirror(e);
              this.setActiveSubMenu("link");
            }}
            className={cx("format-toolbar-item", {
              "is-active": isMarkActive(schema.marks.link)(this.props.state)
            })}>
            <LinkIcon />
          </li>
          <li
            key="format-toolbar-heading"
            onMouseDown={(e) => this.setActiveSubMenu("headings")}
            className={cx("format-toolbar-item", {
              "is-active": getActiveHeading()(this.props.state)
            })}>
            {getActiveHeading()(this.props.state) ? getActiveHeading()(this.props.state) : <H2 />}
            <Caret variant="down" width={16} height={16} />
            {this.state.currentlyActiveSubMenu === "headings" && <Headings setHeading={this.setHeading} />}
          </li>
          <li
            key="format-toolbar-remove-formatting"
            onMouseDown={(e) => removeFormatting(schema)(this.props.state, this.props.dispatch)}
            className={styles["format-toolbar-item"]}>
            <RemoveFormatting {...selectIconSize(this.props.isDesktopSizeViewport)} />
          </li>
        </ul>

        {this.state.currentlyActiveSubMenu === "link" && (
          <Link setLink={this.setLink} attrs={getMarkAttrs(this.props.state, "link")} />
        )}
      </div>
    );
  }
}

interface StateProps {
  isDesktopSizeViewport: boolean;
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    isDesktopSizeViewport: selectIsDesktopSizeViewport(state)
  };
};

const WrappedToolbar = compose(connect(mapStateToProps, () => {}))(Toolbar);

export function toolbar() {
  return new Plugin({
    view(editorView) {
      return new ReactPluginView(editorView, WrappedToolbar, {
        domAttributes: { class: "format-toolbar-container" }
      });
    }
  });
}
