/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { DOMSerializer, Fragment, DOMParser, Node, Slice, MarkType, Mark } from "prosemirror-model";
import { EditorState, Transaction } from "prosemirror-state";
import { schema, paragraphSchema } from "./schema";
import { keymap } from "prosemirror-keymap";
import { baseKeymap } from "prosemirror-commands";
import { storyToPMDoc } from "./story-to-prosemirror";
import { buildKeymap } from "../plugins/keymap";
import textPlaceholder from "../plugins/placeholder";
import { customKeymapPlugin } from "../plugins/handlekeyDown";
import { toolbar } from "../plugins/toolbar/toolbar";
import { PMElement } from "./types";
import { history } from "prosemirror-history";
import { Schema } from "prosemirror-model";
import { safeTextSelection } from "../operations/selection";

export function fragmentToHTML(serializer: DOMSerializer, fragment: Fragment): string {
  const documentFragment = serializer.serializeFragment(fragment);
  let tmp = document.createElement("div");
  tmp.appendChild(documentFragment);
  return tmp.innerHTML;
}

export const editorStateToHtml = (serializer: DOMSerializer, editorState: EditorState) => {
  return fragmentToHTML(serializer, editorState.doc.content);
};

export const EditorStateToHTMLForTextArea = (serializer: DOMSerializer, editorState: EditorState) => {
  const documentFragment = serializer.serializeFragment(editorState.doc.content);
  let tmp = document.createElement("div");
  tmp.appendChild(documentFragment);
  return tmp.innerHTML;
};

const htmlToDomNode = (html: string): ChildNode | null => {
  const parent = document.createElement("div");
  parent.innerHTML = html;
  return parent.firstChild ? parent.firstChild : null;
};

export function htmlToPMNode(parser: DOMParser, html: string): Node | null {
  const node = htmlToDomNode(html);
  return node && parser.parse(node, { preserveWhitespace: true });
}

export function htmlToInlinePMNode(parser: DOMParser, html: string): Slice | null {
  const node: ChildNode | null = htmlToDomNode(html);
  return node ? parser.parseSlice(node) : null;
}

const paragraphParser = DOMParser.fromSchema(paragraphSchema);
const schemaParser = DOMParser.fromSchema(schema);

export default function makeEditorStateFromUnsavedStory(
  story: any,
  editorState: EditorState<Schema> | {} = {}
): EditorState<Schema> {
  const PMStory: PMElement = storyToPMDoc(story, {
    schemaParser,
    paragraphParser,
    editorState
  });

  const PMDoc = Node.fromJSON(schema, PMStory);

  return EditorState.create({
    schema,
    doc: PMDoc,
    selection:
      editorState && "selection" in editorState
        ? safeTextSelection(PMDoc, editorState.selection.anchor, editorState.selection.head)
        : safeTextSelection(PMDoc, 0),
    plugins: [
      history(),
      keymap(buildKeymap(schema)),
      keymap(baseKeymap),

      textPlaceholder(),
      toolbar(),
      customKeymapPlugin(schema)
      //appendTransactions()
    ]
  });
}

export const selectionHasMark = (editorState: EditorState, markType: MarkType): boolean => {
  const { ranges } = editorState.selection;

  let has = false;
  for (let i = 0; !has && i < ranges.length; i++) {
    let { $from, $to } = ranges[i];
    has = editorState.doc.rangeHasMark($from.pos, $to.pos, markType);
  }
  return has;
};

export const addMarkToSelection = (
  editorState: EditorState,
  markType: MarkType,
  markAttrs: { [key: string]: any }
): Transaction => {
  const tr = editorState.tr,
    { ranges } = editorState.selection;

  for (let i = 0; i < ranges.length; i++) {
    let { $from, $to } = ranges[i];
    const mark = markAttrs ? markType.create(markAttrs) : markType.create();
    tr.addMark($from.pos, $to.pos, mark);
  }
  return tr;
};

export const removeMarkFromSelection = (editorState: EditorState, markType: MarkType): Transaction => {
  const tr = editorState.tr,
    { ranges } = editorState.selection;

  for (let i = 0; i < ranges.length; i++) {
    let { $from, $to } = ranges[i];
    tr.removeMark($from.pos, $to.pos, markType);
  }
  return tr;
};

export const getMarkAttrs = (state: EditorState, type: string): { [key: string]: string } => {
  const { from, to } = state.selection;
  let marks = [] as Mark[];

  state.doc.nodesBetween(from, to, (node) => {
    marks = [...marks, ...node.marks];
  });

  const mark = marks.find((markItem) => markItem.type.name === type);

  if (mark) {
    return mark.attrs;
  }

  return {};
};

export const updateQuoteHTMLWithAttribution = (attribution: string | any, quoteHTML: string | undefined) => {
  const attributionText = attribution.name ? `@${attribution.name}` : attribution || "";
  if (quoteHTML) {
    const trimmedQuoteHTML = quoteHTML.replace(/\r?\n|\r/g, " ");
    const contentArray = trimmedQuoteHTML.match(/<blockquote>(.*)<\/blockquote>/);
    const content = contentArray && contentArray[1];
    return `<div><blockquote>${content || ""}</blockquote><span class="attribution">${attributionText}</span></div>`;
  }
  return `<div><blockquote></blockquote><span class="attribution">${attributionText}</span></div>`;
};

export const isTextContentPasteableInStoryElement = (contentNode: Node | null | undefined): boolean => {
  const nodeJSON = contentNode && contentNode.toJSON();
  if (
    nodeJSON &&
    (nodeJSON.type === "text" ||
      nodeJSON.type === "paragraph" ||
      nodeJSON.type === "heading" ||
      nodeJSON.type === "bullet_list" ||
      nodeJSON.type === "ordered_list")
  ) {
    return true;
  }
  return false;
};

export const htmlToPlainText = (html: string): string => {
  if (!html || !document) {
    return html;
  }
  // Create a new div element
  const tempDivElement = document.createElement("div");
  // Set the HTML content with the given value
  tempDivElement.innerHTML = html;
  // Retrieve the text property of the element
  return tempDivElement.textContent || tempDivElement.innerText || "";
};
